import CampoTexto from "../../../Campos/CampoTexto";
import CampoCep from "../../../Campos/CampoCep";
import { Grid } from "@mui/material";
import { Skeleton } from "@mui/material";

export default function EnderecoNacional({
  setData,
  isLoading,
  enderecoDefaultValue,
  onChangeCep,
  cepSelected
}) {

  const handleChange = (e) => {
    let newEndereco = { ...enderecoDefaultValue, [e.target.name]: e.target.value };

    if (e.target.name === "cep") onChangeCep(true);

    setData(newEndereco);
  };

  const handleChangeEnderecoByCEP = (e) => {
    e = e.data;

    let newEndereco = enderecoDefaultValue;
    newEndereco.cidade = e.cidade;
    newEndereco.bairro = e.bairro;
    newEndereco.logradouro = e.logradouro;
    newEndereco.ufDescricao = e.ufDescricao;
    newEndereco.idUf = e.idUf;
    newEndereco.idPais = e.idPais;
    newEndereco.numero = enderecoDefaultValue.numero;
    setData(newEndereco);
  };

  return (
    <Grid
      container
      xs={12}
      rowSpacing={2}
      columnSpacing={{ xs: 1, sm: 1, md: 2 }}
    >
      {isLoading ? (
        <Grid item xs={6}>
          <Skeleton animation="wave" height={60} />
        </Grid>
      ) : (
        <Grid item xs={6}>
          <CampoCep
            handlerChange={handleChange}
            name="cep"
            recarregar={cepSelected}
            size="medium"
            fullWidth={true}
            value={enderecoDefaultValue.cep == null ? "" : enderecoDefaultValue.cep}
            label={"Cep"}
            enable={true}
            setEndereco={handleChangeEnderecoByCEP}
            // Tipo de endereço sendo sempre o de correspondencia
            idTipoEndereco={1}
            idCliente={enderecoDefaultValue.idCliente}
            isEnderecoCliente={enderecoDefaultValue.idCliente > 0}
          />
        </Grid>
      )}
      <Grid item xs={6}>
        {isLoading ? (
          <Skeleton animation="wave" height={60} />
        ) : (
          <CampoTexto
            handlerChange={handleChange}
            name="logradouro"
            fullWidth={true}
            size="medium"
            value={enderecoDefaultValue.logradouro}
            label="Logradouro"
            enable={true}
            defaulValue="Logradouro"
          />
        )}
      </Grid>
      <Grid item xs={6}>
        {isLoading ? (
          <Skeleton animation="wave" height={60} />
        ) : (
          <CampoTexto
            handlerChange={handleChange}
            name="numero"
            fullWidth={true}
            size="medium"
            value={enderecoDefaultValue.numero}
            label="Número"
            enable={true}
            defaulValue="Número"
          />
        )}
      </Grid>
      <Grid item xs={6}>
        {isLoading ? (
          <Skeleton animation="wave" height={60} />
        ) : (
          <CampoTexto
            fullWidth={true}
            handlerChange={handleChange}
            name="complemento"
            size="medium"
            value={enderecoDefaultValue.complemento}
            label="Complemento"
            enable={true}
            defaulValue="Complemento"
          />
        )}
      </Grid>
      <Grid item xs={6}>
        {isLoading ? (
          <Skeleton animation="wave" height={60} />
        ) : (
          <CampoTexto
            fullWidth={true}
            handlerChange={handleChange}
            name="bairro"
            size="medium"
            value={enderecoDefaultValue.bairro}
            label="Bairro"
            enable={true}
            defaulValue="Bairro"
          />
        )}
      </Grid>

      <Grid item xs={6}>
        {" "}
        {isLoading ? (
          <Skeleton animation="wave" height={60} />
        ) : (
          <CampoTexto
            fullWidth={true}
            handlerChange={handleChange}
            name="cidade"
            size="medium"
            value={enderecoDefaultValue.cidade}
            label="Cidade"
            defaulValue="Cidade"
            enable={false}
          />
        )}
      </Grid>

      <Grid item xs={6}>
        {isLoading ? (
          <Skeleton animation="wave" height={60} />
        ) : (
          <CampoTexto
            fullWidth={true}
            handlerChange={handleChange}
            name="UfDescricao"
            size="medium"
            value={enderecoDefaultValue.ufDescricao}
            label={"UF "}
            defaulValue="UF"
            enable={false}
          />
        )}
      </Grid>
    </Grid>
  );
}
