import { Button, Grid } from "@mui/material"
import { Subtitulo, Titulo } from "../../components"
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import { useLocation, useNavigate } from "react-router-dom";
import useAlert from "../../hooks/useAlert";
import { useEffect } from "react";


export const PaginaNaoEncontada = () => {
      const navigate = useNavigate();
      const location = useLocation();
      const { showAlertError } = useAlert();


      useEffect(() => {
            handlerShowError();
      }, [])


      const handlerShowError = () => {
            if (!!location?.state?.message)
                  showAlertError(location.state.message);
      }


      const handlerBack = () => {
            navigate(-2)
      }

      return (
            <Grid container justifyContent={'center'} alignItems={'center'} height={'100vh'} width={'100vw'} >
                  <Grid item xs={12} sx={{ display: 'flex', flexDirection: "column", justifyContent: 'center', alignItems: 'center' }}>
                        <WarningAmberIcon sx={{ fontSize: '3.5rem' }} />
                        <Titulo sx={{ fontSize: '2.5rem' }}>
                              Página não encontrada.
                        </Titulo>
                        <Subtitulo sx={{ fontSize: '1rem', textAlign: 'center' }}>
                              Desculpe, mas a página que você está tentando acessar não existe.<br /> Por favor verifique o endereço e tente novamente.
                        </Subtitulo>
                        <Button onClick={handlerBack} variant="contained" sx={{ mt: '.7rem' }}>
                              Voltar Para o inicio
                        </Button>
                  </Grid>
            </Grid>
      )
}