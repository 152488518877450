import { useState, useEffect } from "react";
import { getLogo } from "../../api/chamadasApi";
import { default as LinkMaterial } from "@mui/material/Link";
import useStyles from "./styles";
import { Hidden } from "@mui/material";

export default function Logo({ idHotsite = 0, isTopDashboard = false }) {
  const [logoBase64, setLogoBase64] = useState("");
  const init = async () => {
    const logoBuscado = await getLogo();
    setLogoBase64(logoBuscado);
  };

  useEffect(() => {
    init();
  }, []);
  const classes = useStyles();

  return (
    logoBase64 !== "" && (
      <LinkMaterial color="inherit" href="/">
        {isTopDashboard === true ? (
          <img
            src={"data:image/png;base64," + logoBase64}
            alt="logo"
            className={classes.logo}
            style={{

            }}
          // height={55}
          />
        ) : (
          <>
            <Hidden smDown>
              <img
                src={"data:image/png;base64," + logoBase64}
                style={{ maxHeight: "130px", maxWidth: "200px" }}
                alt="logo"
              />
            </Hidden>
            <Hidden smUp>
              <img
                src={"data:image/png;base64," + logoBase64}
                style={{ maxHeight: "100px", maxWidth: "100px" }}
                alt="logo"
              />
            </Hidden>
          </>
        )}
      </LinkMaterial>
    )
  );
}
